<template>
  <section class="basic-textarea">
    <div class="row">
      <div class="col-12">
        <b-overlay :show="!userReady" variant="dark" opacity="0.85" blur="2px" rounded="sm">
          <validation-observer ref="accountDefinition" tag="form" v-slot="{ invalid }">
            <b-card>
              <b-tabs v-if="userReady" pills>
                <!-- Tab: Account -->
                <b-tab active>
                  <template #title>
                    <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
                    <span class="d-none d-sm-inline">{{ $t('Account') }}</span>
                  </template>
                  <user-account ref="account" :userData="userData" />
                </b-tab>

                <!-- Tab: Page Permissions -->
                <b-tab v-if="$can('manage', '/Method/GetAllMethods') && $can('manage', '/Method/GetUserMethods')">
                  <template #title>
                    <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
                    <span class="d-none d-sm-inline">
                      {{ $t('PagePermissions') }}
                    </span>
                  </template>
                  <user-page-perms ref="pagePerms" :userMethods="userData.user_method" />
                </b-tab>

                <!-- Tab: Report&Dashboard Permissions -->
                <b-tab v-if="$can('manage', '/Dashboard/GetAllDashboard') && $can('manage', '/Report/GetAllReport')">
                  <template #title>
                    <feather-icon icon="FileTextIcon" size="16" class="mr-0 mr-sm-50" />
                    <span class="d-none d-sm-inline">
                      {{ $t('Report&DashboardPermissions') }}
                    </span>
                  </template>
                  <user-report-and-dashboard-perms ref="reportAndDashboardPerms" :userReports="userData.user_report" :userDashboards="userData.user_dashboard" />
                </b-tab>

                <div @mouseenter="checkForm()">
                  <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="saveChanges()" :disabled="invalid">
                    {{ $t('user_detail.save_changes') }}
                  </b-button>
                  <b-button variant="outline-secondary" type="reset" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="reset()">
                    {{ $t('user_detail.reset') }}
                  </b-button>
                </div>
              </b-tabs>
            </b-card>
          </validation-observer>
        </b-overlay>
      </div>
    </div>
  </section>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink, BOverlay, BButton } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import UserAccount from './components/UserAccount.vue'
import UserPagePerms from './components/UserPagePerms.vue'
import UserReportAndDashboardPerms from './components/UserReportAndDashboardPerms.vue'
import { GET_USER, UPDATE_USER } from '@/store/services/user-service'
import { SET_NOTIFICATION } from '@/store/services/module-service'

export default {
  name: 'user_detail',
  components: {
    BTab,
    BTabs,
    BLink,
    BCard,
    BOverlay,
    BAlert,
    BButton,

    ValidationObserver,

    UserPagePerms,
    UserAccount,
    UserReportAndDashboardPerms
  },
  data() {
    return {
      userData: [],
      userReady: false
    }
  },
  created() {
    this.$store.dispatch(GET_USER, this.$route.params.id).then(response => {
      this.userData = response.data
      this.userReady = true
    })
  },
  methods: {
    saveChanges() {
      // Tüm Veriler Girilmiş
      const account = this.$refs.account.getData()
      let URD = undefined
      let UM = undefined
      if (this.$can('manage', '/Method/GetAllMethods') && this.$can('manage', '/Method/GetUserMethods')) {
        URD = this.$refs.reportAndDashboardPerms.getData()
      }
      if (this.$can('manage', '/Dashboard/GetAllDashboard') && this.$can('manage', '/Report/GetAllReport')) {
        UM = this.$refs.pagePerms.getData()
      }
      const tempPayload = {
        ...account,
        user_id: this.$route.params.id * 1,
        user_method: UM,
        user_dashboard: URD?.user_dashboard,
        user_report: URD?.user_report
      }
      this.$store.dispatch(UPDATE_USER, tempPayload).then(uResponse => {})
    },
    reset() {
      Promise.all([this.$refs.account.reset(), this.$refs.pagePerms.reset(), this.$refs.reportAndDashboardPerms.reset()]).then(() => {
        this.$store.commit(SET_NOTIFICATION, {
          title: this.$t('ResetEvent'),
          status: true,
          message: this.$t('Reset')
        })
      })
    },
    checkForm() {
      this.$refs.accountDefinition.validate()
    }
  }
}
</script>
